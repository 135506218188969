import React, { useCallback } from "react";
import Button from "../../components/Button";
import useContact from "../../data/talons/contact/useContact";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const { insertContactData } = useContact();
  const [Contact, setContact] = useState({});

  const inputHandler = (e) => {
    setContact({ ...Contact, [e.target.name]: e.target.value });
  };
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!Contact.name || Contact.name.length < 2) {
        toast.error("Please Fill Your Full Name", {
          position: "bottom-right",
        });
        return;
      } else if (
        !Contact.email ||
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Contact.email)
      ) {
        toast.error("Please Fill your Email Properly", {
          position: "bottom-right",
        });
        return;
      } else if (!Contact.subject || Contact.subject.length <= 3) {
        toast.error("subject must be at least 4 characters long", {
          position: "bottom-right",
        });
        return;
      } else if (!Contact.message || Contact.message.length <= 3) {
        toast.error("Messaage must be at least 4 characters long", {
          position: "bottom-right",
        });
        return;
      }
      const response = await insertContactData(Contact);
      if (response.id !== null) {
        setContact({
          name: "",
          subject: "",
          email: "",
          message: "",
        });
        toast.success("Your Details is Submitted Succesfully", {
          position: "bottom-right",
        });
      }
    },
    [Contact]
  );

  return (
    <section className="flex justify-center items-center w-full py-20">
      <ToastContainer />
      <form className="flex flex-col gap-4 max-w-screen-lg w-full px-4 md:px-12">
        <div className="flex flex-wrap md:flex-nowrap gap-4 w-full">
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="name" className="text-lg font-light">
              Name
            </label>
            <input
              value={Contact.name}
              onChange={inputHandler}
              type="text"
              placeholder="Name"
              name="name"
              className="w-full bg-transparent border-[1px] border-bgOffWhite py-2 px-4 rounded-md text-lg font-light"
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label htmlFor="email" className="text-lg font-light">
              Email
            </label>
            <input
              value={Contact.email}
              onChange={inputHandler}
              type="email"
              placeholder="Email"
              name="email"
              className="w-full bg-transparent border-[1px] border-bgOffWhite py-2 px-4 rounded-md text-lg font-light"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="subject" className="text-lg font-light">
            Subject
          </label>
          <input
            value={Contact.subject}
            onChange={inputHandler}
            type="text"
            placeholder="Subject"
            name="subject"
            className="w-full bg-transparent border-[1px] border-bgOffWhite py-2 px-4 rounded-md text-lg font-light"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="message" className="text-lg font-light">
            Message
          </label>
          <textarea
            value={Contact.message}
            onChange={inputHandler}
            placeholder="Message"
            name="message"
            className="w-full bg-transparent border-[1px] border-bgOffWhite py-2 px-4 rounded-md text-lg font-light"
            cols="30"
            rows="10"
          />
        </div>
        <Button
          text={`Let's Talk`}
          className={"self-end font-normal"}
          onClick={onSubmit}
        />
      </form>
    </section>
  );
};

export default ContactForm;
