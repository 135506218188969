import React from "react";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt } from "react-icons/fa";

const AnyReactComponent = () => (
  <FaMapMarkerAlt className="h-12 w-8 text-primary" />
);

export default function Map(props) {
  const { center, zoom } = props;

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAF6YsJOwih_sVZfcIYeXzEDoHce60bUno" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={center.lat} lng={center.lng} />
      </GoogleMapReact>
    </div>
  );
}
